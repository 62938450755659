<template>
	<div class="kzp-code">
		<img class="img" src="../../assets/img/service-code.jpg">
		<div class="text">
			查件难？询价慢？<br/>
			快加入快智配查件群。<br/>
			享解放备品24小时查件服务<br/>
			更快更便捷！<br/>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			};
		},
		created() {
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	.kzp-code{
		background-color: rgba(0, 0, 0, 0.08);
		width: fit-content;
		margin: 20vh auto;
		display: flex;
		padding: 10px;
		.img{
			width: 300px;
		}
		.text{
			padding: 0 10px 0 20px;
			font-size: 20px;
			line-height: 36px;
			display: flex;
			align-items: center;
		}
	}
</style>
